
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import TopBar from '@/components/TopBar.vue'
import Footer from '@/components/Footer.vue'
import { getNewsDetails } from '@/api/information'

export default defineComponent({
  name: 'Products',
  components: {
    TopBar,
    Footer,
  },

  setup () {
    const route = useRoute()
    const contentId = route.query.content_id
    const newsDetails = ref({})
    const articleContent = ref('')
    const getInformationDetails = async () => {
      const res : any =  await getNewsDetails({content_id:contentId})
      if (res.meta.success) {
        newsDetails.value = res.data
        let content = res.data.article_content.replace(/data-src/g, "src")
        content = content.replace(/(wx_fmt=gif)|(wx_fmt=png)|(wx_fmt=jpg)|(wx_fmt=jpeg)/g, "")
        articleContent.value = content
      }
    }
    getInformationDetails()
    return {
      contentId,
      newsDetails,
      articleContent,
      getInformationDetails
    }
  }
});
