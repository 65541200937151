import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b4e069c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "author" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.newsDetails.article_title.title), 1),
      _createElementVNode("p", _hoisted_4, "作者：" + _toDisplayString(_ctx.newsDetails.article_title.author) + "    " + _toDisplayString(_ctx.newsDetails.article_title.date), 1),
      _createElementVNode("div", {
        class: "article-box",
        innerHTML: _ctx.articleContent
      }, null, 8, _hoisted_5)
    ]),
    _createVNode(_component_Footer)
  ]))
}